.contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: first baseline;

    width: 90%;
    margin-top: 10px;
    padding: 15px;
    box-shadow: 4px 4px 10px var(--teal);
    border-radius: 20px;

    p{
        width: 100%;
    }

    label{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: first baseline;
        margin-bottom: 20px;
    }

    input, textarea{
        padding: 5px;
        color: var(--main-text-color);
        border: 1px solid var(--main-text-color);
        border-radius: 5px;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
    }

    button{
        font-size: 18px;
        margin-top: 20px;
        padding: 3px 8px;
        background-color: white;
        color: var(--main-text-color);
        border: 1px solid var(--main-text-color);
        border-radius: 10px;
        transition: 0.1s ease-in;
    }

    button:hover{
        background-color: var(--main-text-color);
        color: white;
        cursor: pointer;
        transition: 0.1s ease-out;
    }
}

.short-input{
    width: 30%;
}

.long-input{
    width: 50%;
}

@media (max-width: 768px) {
    .short-input {
        width: 60%;
    }

    .long-input{
        width: 90%;
    }
}