#galaxyBox{
    background: url('../../assets/gifs/galaxy.gif');
    margin: 0px 10px 8px 10px;
	padding: 10px 10px 10px 10px;
	
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.planetBox{
    margin: 30px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
}

.planetGIF{
    width: 100px;
    height: 100px;
}