/* NAVBAR */
.nav{
	background-color: var(--lavender-background);
	font-family: 'Asul', sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px;
	width: 20%;
	z-index: 1;
}

.navHeaderText{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 1;
}

#navTitle{
	font-size: 22px;
}

#navSubtitle{
	font-size: 16px;
}

.navHeader{
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	align-items: center;
	justify-content: center;
}

.navStars {
	overflow: hidden;
	white-space: nowrap;
	max-width: 85%;
	margin-top: 10px;
}

.navLink{
	margin: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.navLinks {
	display: flex;
	flex-direction: column;
	text-align: left;
	list-style-type: none;
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
	gap: 15px;
	width: 90%;
}

.navSeparator{
	overflow: hidden;
	white-space: nowrap;
}

.menuIcon{
    margin: 0px;
    padding: 0px;
    line-height: 0;
	border-color: transparent;
	border-radius: 5px;
	background-color: rgba(211, 211, 242, 1.0);

	top: 5px;
    left: 5px;
	position: fixed;
	display: none;
}

.iconStyle{
    color: var(--main-text-color);
    font-size: 2.5em;
    align-self: center;
}

@media only screen and (max-width: 1100px) {
	.navHeader{
		flex-direction: column;
		align-items: baseline;
		width: 75%;
	}

	#navTitle{
		font-size: 20px;
	}
	
	#navSubtitle{
		font-size: 14px;
	}

	.navStars{
		max-width: 70%;
	}

	.navSeparator{
		width: 80%;
	}
}

@media screen and (max-width: 768px) {
	.nav{
		width: 0%;
	}

	.navHeader{
		display: none;
	}

	.navLinks{
		display: none;
	}

	.navStars{
		display: none;
	}

	.menuIcon {
		display: block;
	}

	.nav.expanded{
		padding-top: 30px;
		padding-bottom: 20px;
		width: 80vw;
		height: fit-content;
		
		top: 0;
		left: 0;
		position: absolute;
		display: block;
	}

	.navHeader.expanded{
		display: flex;
		margin-left: 15px;
	}

	.navLinks.expanded{
		display: flex;
		gap: 10px;
	}

	.navStars.expanded{
		display: flex;
		margin-left: 15px;
		
	}
}